body {
  background-color: #fff;
}

.App {
  text-align: center;
}

#react-burger-cross-btn {
  outline: none;
}

#react-burger-menu-btn {
  outline: none;
}

.bm-burger-bars {
  height: 15% !important;
}

.bm-item:hover {
  color: #fff !important;
}

a, a:visited {
  color: #555;
  text-decoration: underline;
}

a:hover {
  color: #555;
  text-decoration: underline;
  font-style: italic;
}